<template>
	<main class="home">
		<vue-title title="Welcome"></vue-title>
		<div class="container">
			<div class="row mt-3">
				<div class="col-md-8 pb-5">
					<h1>alati.org <small>&ndash; small web apps</small></h1>
					<div class="alert alert-warning my-4"><strong>alati.org</strong> is a collection of simple web applications which will hopefully be useful to someone. I am a software developer and this is my playground for testing code magic.</div>
					<div class="links-home">
						<links />
					</div>
				</div>
				<div class="col-md-4 pb-5">
					<h4>Currency Exchange Rates</h4>
					<div class="alert alert-info">The currency exchange rates are fetched in real-time from the National Bank of Macedonia. The values are in Macedonian denars.</div>

					<div id="xrates">
						<p><button class="btn btn-outline-primary" v-on:click="getXRates"><strong>$</strong> Get Rates</button></p>
						<div v-html="rates"></div>
					</div>
				</div>
			</div>
		</div>
	</main>
</template>

<script>
import axios from 'axios';

export default {
	name: 'home',
	data: function () {
		return {
			rates: ''
		}
	},
	methods: {
		getXRates: function () {
			var vm = this;
			vm.rates = 'Loading...';
			axios.get('xrates.php')
				.then(function (response) {
					if (response.status === 200) {
						vm.rates = response.data;
					} else {
						vm.rates = 'Error fetching the exchange rates.';
					}
				})
				.catch(function () {
					vm.rates = 'Error fetching the exchange rates. ';
				});
		}
	}
}
</script>
