<script>
const appTitle = " | Alati.org (Free Web Tools)";
export default {
	name: "vue-title",
	props: ["title"],
	created() {
		document.title = this.title + appTitle;
	},
	watch: {
		title() {
			document.title = this.title + appTitle;
		}
	},
	render() {
		return null;
	}
};
</script>