<template>
	<ul class="links">
		<li>
			<router-link to="/enmk" title="English-Macedonian Character Converter">
				<svg-icon icon="enmk"></svg-icon>
				<span class="short">en-mk</span>
				<span class="full">English-Macedonian Character Converter</span>
			</router-link>
		</li>
		<li>
			<router-link to="/char" title="HTML Codes for Unicode Characters">
				<svg-icon icon="char"></svg-icon>
				<span class="short">html chars</span>
				<span class="full">HTML Codes for Unicode Characters</span>
			</router-link>
		</li>
		<li>
			<router-link to="/font" title="Macedonian Cyrillic Fonts (not Unicode)">
				<svg-icon icon="font"></svg-icon>
				<span class="short">mk fonts</span>
				<span class="full">Macedonian Cyrillic Fonts (not Unicode)</span>
			</router-link>
		</li>
		<li>
			<router-link to="/rsg" title="Random String &amp; Password Generator">
				<svg-icon icon="rsg"></svg-icon>
				<span class="short">r@nd0m</span>
				<span class="full">Random String &amp; Password Generator</span>
			</router-link>
		</li>
	</ul>
</template>


<script>
export default {
	name: 'Links'
}
</script>
