import Vue from 'vue'
import Router from 'vue-router'
import Home from './views/Home.vue'

Vue.use(Router)

export default new Router({
	routes: [
		{
			path: '/',
			name: 'home',
			component: Home
		},
		{
			path: '/enmk',
			name: 'enmk',
			component: () => import(/* webpackChunkName: "enmk" */ './views/EnMk.vue')
		},
		{
			path: '/char',
			name: 'char',
			component: () => import(/* webpackChunkName: "char" */ './views/Char.vue')
		},
		{
			path: '/font',
			name: 'font',
			component: () => import(/* webpackChunkName: "font" */ './views/Font.vue')
		},
		{
			path: '/rsg',
			name: 'rsg',
			component: () => import(/* webpackChunkName: "rsg" */ './views/Rsg.vue')
		}
	]
})
