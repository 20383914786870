import Vue from 'vue';
import App from './App.vue';
import router from './router';
import VueClipboard from 'vue-clipboard2';
	Vue.use(VueClipboard);


import linksComponent from './components/Links.vue';
	Vue.component('links', linksComponent);
import pageTitleComponent from './_js/PageTitle.vue';
	Vue.component('vue-title', pageTitleComponent);
import svgIconComponent from './components/SvgIcon.vue';
	Vue.component('svg-icon', svgIconComponent);


Vue.config.productionTip = false;

new Vue({
	router,
	render: h => h(App)
}).$mount('#app');
